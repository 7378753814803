import PropTypes from 'prop-types';

export const GetVendorWebsite = {
    location_id: PropTypes.number,
    zip_code: PropTypes.string,
    address: PropTypes.string,
    city_local: PropTypes.string,
    taluka: PropTypes.string,
    district: PropTypes.string,
    state: PropTypes.string,
    region: PropTypes.string,
    vendor_id: PropTypes.number,
    setting_value_id: PropTypes.string,
    website_id: PropTypes.string,
    org_name: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    mobile_no: PropTypes.string,
    estb_year: PropTypes.number,
    banner_img: PropTypes.string,
  };