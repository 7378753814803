
import React from 'react';

const MultiService = ({ title }) => {
    return (
        <>
            <div className="text-slate-900 dark:text-dark pt-5 mb-2 text-2xl font-semibold tracking-tight capitalize text-center">
                Other Services
            </div>
            <div className="bg-white light:bg-slate-900 rounded-lg h-48 px-6 py-8 ring-1 ring-slate-900/5 shadow-xl text-center">
                <span className="inline-flex items-center justify-center p-2 bg-indigo-500 rounded-md shadow-lg">
                    <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                </span>
                <h3 className="text-slate-900 dark:text-dark mt-5 text-base font-medium tracking-tight">
                    {title ? title : ""}
                </h3>
            </div>
        </>
    );
};

export default MultiService;