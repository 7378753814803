
import React, { useEffect, useState } from 'react';
import Title from 'shared/component/title';
import SubTitle from 'shared/component/subtitle';
import ServiceCard from 'shared/component/service-card';
import { httpService } from 'services/http-service';
import NoData from 'shared/component/no-data';
import { setSession } from 'core/utility';
import { VendorId } from 'services/constant';
import PlaceholderLoader from 'shared/component/placeholder-loader';
import MultiService from 'shared/component/multi-service';

function VendorServices({ websiteData }) {
    const { vendor_id, setting_value_id } = websiteData;
    const [serviceData, setServiceData] = useState([]);
    const [loader, setLoader] = useState(true);
    // In case of vendor dealing in multiple business take the first business
    const busness_id = setting_value_id?.toString().split(',')[0];

    useEffect(() => {
        if (vendor_id && setting_value_id && busness_id) {
            httpService(`LbVendorService/GetVendorService?vendorId=${vendor_id}&businessType=${busness_id}`, {
                method: "GET",
            }).then(response => {
                if (response) {
                    setServiceData(JSON.parse(response?.service_data));
                    // Set Vendor Id in Session to access in all component
                    setSession(VendorId, response?.vend_serv_id);
                    setLoader(false)
                }
            });
        }
    }, [websiteData]);
    return (
        <section
            id="service"
            aria-label="Service section"
            className="container mx-auto my-12"
        >
            <div className="text-center">
                <Title text={"Our services"} />
                <SubTitle
                    text={
                        "We provide the different type of service in Videography and Photography"
                    }
                />
            </div>
            {
                serviceData.length ? (
                    <>
                        <div className="flex flex-wrap justify-center">
                            {serviceData?.map((value) => (
                                <div key={value?.setting_value_id} className="hover:scale-105 duration-300 ease-in-out w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-4">
                                    <ServiceCard
                                        title={value?.setting_value}
                                        subTitle={value?.service_description}
                                    />
                                </div>
                            ))}
                        </div>
                        <div>
                            <MultiService title={websiteData?.business_types}/>
                        </div>
                    </>
                ) : (
                    loader ? <PlaceholderLoader className="m-4" numberOfCard={4} /> :
                        serviceData.length === 0 ? <NoData text="No Service is listed by the company" /> : ''
                )
            }
        </section>
    );
}

export default VendorServices;