import React, { useEffect, useState } from "react";
import { httpService } from "./services/http-service";
import Header from "./components/header";
import BookNow from "./components/book-now/book-now";
import Testimonials from "./components/testimonials/testimonials";
import ContactUs from "./components/contact-us/contact-us";
import Footer from "./components/footer";
import VendorServices from "./components/vendor-services/vendor-services";
import Gallery from "./components/gallery/gallery";
import { useLocation, useNavigate } from "react-router-dom";
import { UserAccessToken } from "./services/constant";
import { getSession, setSession } from "./core/utility";
import AlertDialog from "shared/component/alert-dialog";


function App() {
  const [businessName, setBusinessName] = useState('');
  const [websiteData, setWebsiteData] = useState({});
  const [isToken, setIsToken] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const webSiteId = location.pathname.slice(1);
  const token = searchParams.get('token');
  let url = '';

  const encodeUrl = btoa(process.env.REACT_APP_PREFIX_TOKEN);
  if (webSiteId) {
    url = process.env.REACT_APP_LAGANBOOKING_DOMAIN + `/#/signin?redirect=${encodeUrl}&websiteId=${webSiteId}` || '';
  } else {
    url = process.env.REACT_APP_LAGANBOOKING_DOMAIN;
  }

  useEffect(() => {
    // set Token to to access it from everywhere
    if ((token && searchParams.has('token')) || getSession(UserAccessToken) != null) {
      setSession(UserAccessToken, token);
      if (webSiteId?.length > 2)
        httpService(`LbVendor/GetVendorWebsiteData/?websiteId=${webSiteId}`, {
          method: "GET",
        }).then(response => {
          // localStorage.setItem(vendorKey, JSON.stringify(response));
          setBusinessName(response?.org_name);
          setWebsiteData(response);

          // delete Token from url once all component loaded
          searchParams.delete('token');
          navigate({ search: searchParams.toString() });
        });
      setIsToken(true)
    } else {
      setIsToken(false)
    }
  }, []);

  return (
    <div>
      <Header websiteData={websiteData}/>
      <BookNow websiteData={websiteData} />
      <VendorServices websiteData={websiteData} />
      <Gallery websiteData={websiteData} />
      <Testimonials websiteData={websiteData} />
      <ContactUs websiteData={websiteData} />
      <Footer />
      {!isToken && <AlertDialog url={url}></AlertDialog>}
    </div>
  );
}

export default App;