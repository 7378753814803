import React from 'react';

const SubTitle = ({ text }) => {
    return (
      <div className="mt-2 text-slate-400 text-lg dark:text-dark tracking-tight ">
        {text ? text : ""}
      </div>
    );
  };

export default SubTitle;