import React from 'react';

const Title = ({ text }) => {
    return (
        <div className="text-slate-900 dark:text-dark pt-5 text-4xl font-semibold tracking-tight capitalize">
            {text ? text : ""}
        </div>
    );
};

export default Title;