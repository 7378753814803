
import React from 'react';

const ServiceCard = ({ img, title, subTitle }) => {
    return (
        <div className="bg-white light:bg-slate-900 rounded-lg h-48 px-6 py-8 ring-1 ring-slate-900/5 shadow-xl text-center">
            <span className="inline-flex items-center justify-center p-2 bg-indigo-500 rounded-md shadow-lg">
                <svg
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    ></path>
                </svg>
            </span>
            <h3 className="text-slate-900 dark:text-dark mt-5 text-base font-medium tracking-tight">
                {title ? title : ""}
            </h3>
            <p className="text-slate-500 dark:text-slate-400 mt-2 text-sm">
                {subTitle ? subTitle : ""}
            </p>
        </div>
    );
};

export default ServiceCard;