import { useState } from "react";
import { GetVendorWebsite } from "../shared/models/GetVendorWebsiteData";

Header.propTypes = { ...GetVendorWebsite };

export default function Header({websiteData}) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div>
            {/* <------Mobile Screen--------->*/}
            <div
                className={`${window.innerWidth < 768 ? "block mt-56" : "hidden" // Show only on screens below 768px
                    } top-0 left-0 right-0 z-50 flex flex-col bg-white light:bg-gray-900 shadow-md overflow-hidden`}
            >
                <div className="fixed top-0 left-0 right-0 z-50 flex flex-col bg-white light:bg-gray-900 shadow-md overflow-hidden">
                    {/* Menu header with a button to toggle */}
                    <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
                        <h2 className="text-xl font-bold ">{websiteData?.org_name}</h2>
                        <button
                            onClick={toggleMenu}
                            className="focus:outline-none focus:ring-2"
                            type="button"
                        >
                            <svg
                                className="w-6 h-6 text-gray-600 dark:text-gray-800"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button>
                    </div>

                    {/* Menu items */}
                    <ul
                        className={`${isOpen ? "block" : "hidden"
                            } p-4 space-y-2 text-left font-medium`}
                    >
                        <li>
                            <a href="#home" className=" hover:text-blue-500">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="#about" className=" hover:text-blue-500">
                                About
                            </a>
                        </li>
                        <li>
                            <a href="#service" className="hover:text-blue-500">
                                Service
                            </a>
                        </li>
                        <li>
                            <a href="#contact" className="hover:text-blue-500">
                                Contact
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            {/* <------Desktop Screen--------->*/}
            <header
                className={`${window.innerWidth > 768 ? "block" : "hidden"
                    } flex justify-between items-center my-2 px-2`}
            >
                <h1 className="text-2xl font-bold p-2">{websiteData?.org_name}</h1>
                <nav className="flex justify-center space-x-4">
                    <a
                        href="#home"
                        className="font-medium px-3 py-2 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900"
                    >
                        Home
                    </a>
                    <a
                        href="#about"
                        className="font-medium px-3 py-2 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900"
                    >
                        About
                    </a>
                    <a
                        href="#service"
                        className="font-medium px-3 py-2 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900"
                    >
                        Service
                    </a>
                    <a
                        href="#gallery"
                        className="font-medium px-3 py-2 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900"
                    >
                        Gallery
                    </a>
                    <a
                        href="#contact"
                        className="font-medium px-3 py-2 text-slate-700 rounded-lg hover:bg-slate-100 hover:text-slate-900"
                    >
                        Contact
                    </a>
                </nav>
            </header>
        </div>
    );
};